<template>
  <span>
    <v-btn style="margin-left: 5px; margin-right: 5px;" :disabled="disabled" :fab="!!fabicon" :small="this.sizes['small']" :x-small="this.sizes['x-small']" :large="this.sizes['large']" :x-large="this.sizes['x-large']"  :color="this.color || 'success'" @click="openDialog()">
      <span v-if="!fabicon">{{this.buttontext}}</span>
      <v-icon v-else>{{fabicon}}</v-icon>
      </v-btn>
    <v-dialog
        v-model="dialog.isOpen"
        persistent
        width="500px"
    >
      <v-card>
        <v-card-title class="text-h5 d-flex flex-column align-center justify-center">
          <span v-if="this.simpleConfirm" class="d-flex flex-column align-center justify-center">
              <b>Confirm Operation</b>
              <span class="d-flex flex-row mt-5">
                <v-btn color="error" small @click="closeDialog()">Cancel</v-btn>
                <v-btn style="margin-left: 5px;" color="info" small @click="attemptSimpleConfirmAuth()">Confirm</v-btn>            
              </span>
          </span>
          <span class="d-flex flex-column" v-else>
            <v-progress-circular
                indeterminate
                color="green"
                v-if="dialog.awaitingScanResult"
                style="margin-top: 20px; margin-bottom: 5px;"
            ></v-progress-circular>
            <b>{{dialog.text}}</b>
            <v-btn v-if="!usePin" small color="info" style="margin-top: 15px;" @click="openUsePin()">Use PIN Instead</v-btn>
            <span v-else>
              <v-text-field type="password" inputmode="numeric" label="PIN" v-model="userPin"></v-text-field>
              <v-btn color="error" small @click="closeUsePin()">Cancel</v-btn>
              <v-btn style="margin-left: 5px;" color="info" small @click="attemptPINAuth()">Submit</v-btn>
            </span>
          </span>
        </v-card-title>
        <v-card-text>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" text @click="closeDialog()">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </span>
</template>

<script>
import {mapGetters, mapMutations} from 'vuex'
import scanSystem from "../plugins/scanSystem"
export default {
  name: 'authorizer',
  props: {
    authorization: Object,
    buttontext: String,
    color: String,
    fabicon: String,
    disabled: Boolean,
    size: String,
    simpleConfirm: Boolean,
  },
  data: () => ({
    dialog: {
      isOpen: false,
      awaitingScanResult: false,
      text: '',
    },
    usePin: false,
    sizes: {
      'x-small': false,
      'small': false,
      'x-large': false,
      'large': false,
    },
    userPin: '',
    userName: '',
    scanString: '',
    prevScanBus: null,
  }),
  mounted(){
    if(this.size){
      for(let x in this.sizes){
        this.sizes[x] = false
      }
      this.sizes[this.size] = true
    }
  },
  computed: {
    ...mapGetters([
      'getId',
      'getEndpoint',
      'lookupUserName',
      'getUser',
      'scanBus',
      'lookupUsername',
      'isLoggedIn'
      ]),
  },
  methods: {
    ...mapMutations([
      'updateWorkingId',
      'deleteWorkingId',
      'lockGlobalQueryBc',
      'unlockGlobalQueryBc',
      'setScanBus',
      'resetScanBus',
    ]),
    async openDialog(){
      try {
        this.prevScanBus = {...this.scanBus}
        this.dialog.isOpen = true
        this.dialog.text = "Awaiting Scan"
        window.addEventListener('keypress', this.scanListener);
      } catch (error) {
        console.log(error)
      }
    },
    closeDialog(){
      this.dialog = {
        isOpen: false,
        awaitingScan: '',
        text: '',
      }
      this.scanString = ''
      window.removeEventListener('keypress', this.scanListener)
    },
    scanListener(e){
      //SID Listener
      if(this.isLoggedIn && this.scanBus.locked && this.scanBus.type=="authorizer"){ //to use barcode services an invoker must use the scanbus and specify a type, else the daemon will attempt to validate for a job
        if(e.key == 'Enter'){ // The barcode scanner sends the Enter key at the end of a read op.
          let validatedType = scanSystem.validateType(this.scanString)
          if(validatedType==="VSID"){
            this.$emit('response', {pin:null,vsidToken:this.scanString.replace(/\\\/\/\\=/,''), id: this.getId})
            this.closeDialog()
          }else{
            this.resetScanBus()
            console.log("🔁")
            this.closeDialog()
          }
        }else{
          if(!this.scanString) this.scanString = ""
          this.scanString = this.scanString + e.key
        }
      }
    },
    attemptPINAuth(){
      this.$emit('response', {pin:this.userPin,vsidToken:null,id:this.getUser.id})
      this.closeDialog()
    },
    openUsePin(){
      this.usePin = true
    },
    closeUsePin(){
      this.usePin = false
      this.userPin = ''
      this.userName = ''
    },
    attemptSimpleConfirmAuth(){
      this.$emit('response', {id:this.getId, simpleConfirm: true})
      this.closeDialog()
    },
  }
}
</script>
