<template>
  <div style="width: 100%;">
    <v-row>
      <v-col>
        <v-container fluid>
          <v-row>
            <v-col class="d-flex flex-row align-center">
              <v-btn text class="mx-2" @click="()=>{this.$router.go(-1)}">
                <v-icon dark>mdi-arrow-left</v-icon>
              </v-btn>
              <h1>{{this.plural}}</h1>
              <v-progress-circular
                  indeterminate
                  color="green"
                  v-if="loader"
                  style="margin-left: 10px;"
              ></v-progress-circular>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-data-table
                  :headers="headers"
                  :items="allData"
                  :items-per-page="-1"
                  class="elevation-1"
                  @click:row="rowClick"
              >
              </v-data-table>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
      <v-col v-if="isAllowed('productAttribute','c')" cols="4">
        <v-container>
          <v-row>
            <v-col class='d-flex flex-row align-center'>
              <h1>Add New {{this.singular}}</h1>
            </v-col>
            <v-col>
              <v-progress-circular
                  indeterminate
                  color="green"
                  v-if="loader"
              ></v-progress-circular>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                  label="Name"
                  outlined
                  v-model="data.name"
              />
              <v-text-field
                  label="Slug"
                  outlined
                  v-model="data.slug"
                  persistent-hint
                  hint="Spaces within the Slug will be replaced with '-' automatically."
              />
              <v-select
                  item-text="name"
                  item-value="value"
                  :items="typeValues"
                  label="Type"
                  outlined
                  v-model="data.type"
              />
              <v-select
                  item-text="name"
                  item-value="value"
                  :items="orderByValues"
                  label="Order By"
                  outlined
                  v-model="data.orderBy"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-btn @click="create" :disabled="!this.data.name">Create</v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
    <v-snackbar v-model="snackObj.state" :timeout="3000" :color="snackObj.color">
      {{ snackObj.text }}
      <template v-slot:action="{ attrs }">
        <v-btn v-bind="attrs" text @click="snackObj.state = false">Close</v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<script>
  import axios from 'axios';
  import {mapGetters} from "vuex";

  export default {
    data () {
      return {
        deleteDialog: false,
        deleteConfirmed: true,
        loader: true,

        snackObj: {
          state: false,
          color: '',
          text: ''
        },

        max25chars: v => v.length <= 25 || 'Input too long!',
        headers: [
          {
            text: 'Name',
            align: 'start',
            sortable: true,
            value: 'name',
          },
          { text: 'Slug', value: 'slug' },
          { text: 'Type', value: 'type' },
          { text: 'Order By', value: 'orderBy' },
          { text: 'Terms', value: 'numTerms' }
        ],
        editMode: false,
        data: {
          type: "select",
          orderBy: "menu_order"
        },
        allData: [],
        orderByValues: [
          {name: "Menu Order", value: "menu_order"}, {name: "Name", value: "name"}, {name: "Numeric Name", value: "name_num"}, {name: "ID", value: "id"}
        ],
        typeValues: [
          {name: "Select", value: "select"}
        ],
        singular: "Product Attribute",
        singularLower: "productattribute",
        plural: "Product Attributes",
        pluralLower: "productattributes"
      }
    },
    async mounted(){
        await this.getAllData();
    },
    computed: {
      ...mapGetters(['getEndpoint', "isAllowed"])
    },
    methods: {
      rowClick(row){
        this.$router.push({ path: `/${this.pluralLower}/view/${row.id}`})
      },
      snack(text, color=""){
        this.snackObj.text = text;
        this.snackObj.state = true;
        this.snackObj.color = color;
      },
      async create(){
        try {
          let res = await axios.post(`${this.getEndpoint}/api/${this.pluralLower}`, this.data)
          if(res.data.error) throw res.data.error

          this.snack(`${this.singular} Created 🎉`, "success");
          this.data={
            type: "select",
            orderBy: "menu_order"
          };
          await this.getAllData();
        } catch (error) {
          console.error(error)
          this.snack(error.msg || error, "error");
        }
      },
      async getAllData(){
        try {
          let res = await axios.get(`${this.getEndpoint}/api/${this.pluralLower}`)
          if(res.data.error) throw res.data.error

          this.allData = res.data.data
        } catch (error) {
          console.error(error);
          this.snack(error.msg || error, "error");
        } finally {
          this.loader = false;
        }
      }
    }
  }
</script>
